import { ArrowForward } from "@mui/icons-material";
import { Divider, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Fragment, useState } from "react";
import { userInfo } from "../../../shared/utils";
import AccountCompany from "./AccountCompany";
import AccountEmail from "./AccountEmail";
import AccountLocation from "./AccountLocation";
import AccountPassword from "./AccountPassword";
import AccountPhone from "./AccountPhone";

export default function Account() {

    const [activeView, setActiveView] = useState(-1)

    const items = [
        {
            name: "Company Name",
            value: userInfo().user.companyName,
            component: <AccountCompany onBack={() => setActiveView(-1)} />
        },
        {
            name: "Phone Number",
            value: "?",
            component: <AccountPhone onBack={() => setActiveView(-1)} />
        },
        {
            name: "Email",
            value: userInfo().user.userName,
            component: <AccountEmail onBack={() => setActiveView(-1)} />
        },
        {
            name: "Location",
            value: "",
            component: <AccountLocation onBack={() => setActiveView(-1)} />
        },
        {
            name: "Password",
            value: "",
            component: <AccountPassword onBack={() => setActiveView(-1)} />
        }
    ]

    return (
        <div className="account">
            <div className="container">
                <div className="header">
                    <div className="picture">

                    </div>
                </div>
                <div className="body">
                    {activeView === -1
                        ? (
                            <List>
                                {items.map((item: any, i: number) => (
                                    <Fragment key={i}>
                                        <ListItem dense>
                                            <ListItemButton onClick={() => setActiveView(i)}>
                                                <ListItemText>
                                                    <div className="item">
                                                        <span>{item.name}</span>
                                                        <span>{item.value}</span>
                                                    </div>
                                                </ListItemText>
                                                <ArrowForward color="primary" />
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider />
                                    </Fragment>
                                ))}
                            </List>
                        )
                        : (
                            items[activeView].component
                        )
                    }
                </div>
            </div>
        </div>
    )
}