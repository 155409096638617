import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export default function AccountPhone(props: any) {
    return (
        <div className="detail">
            <IconButton onClick={props.onBack}>
                <ArrowBack color="primary" />
            </IconButton>
        </div>
    )
}