import { ArrowCircleRightOutlined, CheckOutlined, CloseOutlined } from "@mui/icons-material";
import { CircularProgress, Divider, IconButton, Popover, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { userInfo } from "../../../shared/utils";
import { getProjectBidList, getProjectByCompany, projectApproveBid } from "../project.api";

export default function ProjectProposeList(props: any) {

    const dispatch = useDispatch()

    const [popBid, setPopBid] = useState<HTMLButtonElement | null>(null);
    const [loading, setLoading] = useState(false)
    const [bidderList, setBidderList] = useState<any>([])

    const handlePop = (e: any) => {
        setLoading(true)
        const target = e.currentTarget
        dispatch<any>(getProjectBidList({
            token: userInfo().token,
            projectId: props.data.id
        }))
            .unwrap()
            .then((response: any) => {
                setBidderList(response.data.data)
                setPopBid(target)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleApprove = (id: string) => {
        dispatch<any>(projectApproveBid({
            token: userInfo().token,
            projectBidId: id
        }))
            .unwrap()
            .then((response: any) => {
                Swal.fire("Success")
                let countyList: any = []
                userInfo().user.companyStates.map((state: any) => (
                    state.counties.map((county: any) => (
                        countyList.push(county)
                    ))
                ))
                dispatch<any>(getProjectByCompany({
                    skip: 0,
                    take: 100,
                    token: userInfo().token,
                    companyId: userInfo().user.companyId,
                    filter: ""
                }))
            })
    }

    return (
        <Fragment>
            <Popover
                id="1"
                open={Boolean(popBid)}
                anchorEl={popBid}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setPopBid(null)}
            >
                <div className="propose-list">
                    <Typography align="center">
                        <b>Propose Bid</b>
                    </Typography>
                    <Divider />
                    <table>
                        <tbody>
                            {bidderList.map((propose: any, i: number) => (
                                <tr key={i}>
                                    <td>{propose.bidderCompany}</td>
                                    <td>{propose.bidAmount}</td>
                                    <td></td>
                                    <td>
                                        <IconButton color="primary" onClick={() => handleApprove(propose.id)}>
                                            <CheckOutlined />
                                        </IconButton>
                                    </td>
                                    <td>
                                        <IconButton color="error">
                                            <CloseOutlined />
                                        </IconButton>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Popover>
            <IconButton aria-describedby={'1'} color="primary" onClick={handlePop}>
                {loading
                    ? <CircularProgress size={20} />
                    : <ArrowCircleRightOutlined />
                }
            </IconButton>
        </Fragment>
    )
}