import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const getVerification = createAsyncThunk('/api/Company/UFLGetNewlyRegisteredCompany', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/api/Company/UFLGetNewlyRegisteredCompany',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const sendVerification = createAsyncThunk('/api/Account/SendVerification', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/api/Account/SendVerification',
            params: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})