import { Backdrop, Button, CircularProgress, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import logo from "../../../assets/images/logo.svg";
import { RootState } from "../../../store";
import { activateCompany } from "../auth.api";

export default function AuthVerification() {

    const dispatch = useDispatch()

    const { loading } = useSelector((state: RootState) => state.auth)

    useEffect(() => {
        let url = window.location.hash
        const params = new URLSearchParams(url.slice(url.indexOf('?')));
        const token = decodeURIComponent(params.get('token') || "")
        const email = decodeURIComponent(params.get('email') || "")
        dispatch<any>(activateCompany({
            token: token,
            email: email
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    window.location.replace("https://uflink.utilityfielding.com")
                }
                else {
                    Swal.fire({
                        icon: "error",
                        text: response.data
                    })
                }
            })
    }, [])


    return (
        <div className="verification">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container>
                <Typography variant="h2">
                    <span>
                        Hi, Welcome to
                    </span>
                    <img src={logo} alt="logo" />
                </Typography>
                <Typography variant="body1" marginTop={10}>
                    Your have been successfully activated. <br />
                </Typography>
                <br />
                <Button variant="contained" onClick={() => window.location.replace("https://uflink.utilityfielding.com")}>
                    Log in
                </Button>
                <br />
                <br />
                <Typography variant="body1">
                    If you have any question regarding our Platform. Feel free to contact <br />
                    us at contact@uflink or call us at 734-992-3482 <br />
                </Typography>
                <Typography variant="body1" marginTop={10}>
                    Thank You, <br />
                    Uflink Team <br />
                </Typography>
                <Typography variant="body1" marginTop={10}>
                    UFLINK <br />
                    3281 E Guasti Rd Ontario <br />
                    CA 91761-7622 <br />
                    contact@uflink.com <br />
                    734-992-3482 <br />
                </Typography>
            </Container>
        </div>
    )
}