import { useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '100%'
};

export default function ProjectCreateMap(props: any) {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDQ-aesOz_ut1yBv_d6FG-0N60uqDwgLZs'
    })

    const [center, setCenter] = useState({
        lat: 40.8163362,
        lng: -100.5224534
    })
    const [zoom, setZoom] = useState(0)

    useEffect(() => {
        if (isLoaded) {
            let i = setTimeout(() => {
                setZoom(3)
                clearInterval(i)
            }, 100)
        }
    }, [isLoaded])

    useEffect(() => {
        if (props.county !== "") {
            console.log(props.county)
            searchByName(props.county)
        }
    }, [props.county])

    const onLoad = (map: any) => {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        map.addListener("zoom_changed", () => {
            setZoom(map.zoom)
        });
    }

    const searchByName = async (value: any) => {
        const geocoder = new google.maps.Geocoder()
        let response = await geocoder.geocode({ address: value })
        console.log(response)
        let lat = response.results[0].geometry.location.lat();
        let lng = response.results[0].geometry.location.lng();
        setCenter({ lat: lat, lng: lng })
        setZoom(8)
    }

    const handleClick = async (coord: any) => {
        let lat = coord.latLng.lat()
        let lng = coord.latLng.lng()
        console.log(coord)
        const geocoder = new google.maps.Geocoder()
        let response = await geocoder.geocode({ location: coord.latLng })
        console.log(response.results[0])
        props.setValues({
            ...props.values,
            address: response.results[0]?.formatted_address || "",
            location: `${lat},${lng}`,
            city: response.results[0].address_components.length > 5 ? response.results[0].address_components[2]?.long_name || "" : response.results[0].address_components[1]?.long_name || ""
        })
    }

    return isLoaded ? (
        <GoogleMap
            onLoad={onLoad}
            mapContainerStyle={containerStyle}
            zoom={zoom}
            center={center}
            onClick={handleClick}
        >
        </GoogleMap>
    ) : <></>
}