import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Card, CardContent, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../store";
import { createCompany } from "../auth.api";
import { setFormSignup } from "../auth.reducer";

export default function AuthSignUpD() {

    const dispatch = useDispatch()

    const { formSignup } = useSelector((state: RootState) => state.auth)

    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

    const handleChangeForm = (e: any) => {
        const { name, value } = e.target
        dispatch(setFormSignup({
            ...formSignup,
            [name]: value
        }))
    }

    const handleSend = (e: any) => {
        e.preventDefault()
        dispatch<any>(createCompany(formSignup))
            .unwrap()
            .then((response: any) => {
                console.log(response)
                if (response.status === 200) {
                    if (response.data.status) {
                        Swal.fire({
                            title: "Almost there !",
                            text: `
                            Thank you for registering your account!
                            Your account need the approval before login.
                            We'll Contact you by phone, we hope for your kind waiting
                            `,
                            footer: "Promblem or question ? Email us as Contact@uflink.com"
                        })
                            .then(() => {
                                window.location.replace('/')
                            })
                    }
                    else {
                        Swal.fire({
                            title: "Error",
                            text: response.data.message,
                            footer: "Promblem or question ? Email us as Contact@uflink.com"
                        })
                    }
                }
                else {
                    let errorMsg = response.data.errors ? Object.values(response.data.errors) : []
                    Swal.fire({
                        title: "Error",
                        text: errorMsg[0] || response.data.message || response.statusText,
                        footer: "Promblem or question ? Email us as Contact@uflink.com"
                    })
                }
            })
            .catch((err: any) => {
                Swal.fire({
                    title: "Error",
                    text: err,
                    footer: "Promblem or question ? Email us as Contact@uflink.com"
                })
            })
    }

    return (
        <Grid item md={6}>
            <Card elevation={20}>
                <CardContent>
                    <Typography variant="h5">Create New Company</Typography>
                    <Typography variant="inherit" color="GrayText">Input Password</Typography>
                    <br />
                    <form onSubmit={handleSend}>
                        <TextField
                            margin="dense"
                            fullWidth
                            onChange={handleChangeForm}
                            type={showPassword ? "text" : "password"}
                            label="Password"
                            name="password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            style={{ marginTop: 0 }}
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            onChange={handleChangeForm}
                            type={showPasswordConfirm ? "text" : "password"}
                            label="Password Confirmation"
                            name="passwordConfirm"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            style={{ marginTop: 0 }}
                                            onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                        >
                                            {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Typography variant="inherit" color="GrayText" align="right">04/04</Typography>
                        <Button type="submit" variant="contained">Send</Button>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    )
}