import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const createCompany = createAsyncThunk('/api/Company/UFLCreate', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/api/Company/UFLCreate',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const activateCompany = createAsyncThunk('/api/Account/ConfirmEmail', async (data: any) => {
    try {
        const response = await axios({
            method: 'get',
            url: baseUrl + '/api/Account/ConfirmEmail',
            params: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const login = createAsyncThunk('/api/Account/Login', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/api/Account/Login',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})