import { ArrowCircleDown } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { DateRangePicker } from "material-mui-date-range-picker";
import { useState } from "react";

export default function Credit() {

    const [openDate, setOpenDate] = useState(true)
    const [dataList, setDataList] = useState<any>([
        {
            transactionId: "transactionId",
            date: "date",
            amount: "amount",
            method: "method",
            status: false
        },
        {
            transactionId: "transactionId",
            date: "date",
            amount: "amount",
            method: "method",
            status: true
        }
    ])

    const columns = [
        {
            name: "Transaction ID",
            key: "transactionId"
        },
        {
            name: "Date",
            key: "date"
        },
        {
            name: "Amount",
            key: "amount"
        },
        {
            name: "Payment Method",
            key: "method"
        },
        {
            name: "Payment Status",
            key: "status",
            customRender: (props: any) => (
                <span className={"status " + (props.data.status ? "success" : "declined")}>
                    {props.data.status ? "Success" : "Declined"}
                </span>
            )
        },
        {
            name: "Invoice",
            key: "",
            customRender: (props: any) => (
                <IconButton>
                    <ArrowCircleDown fontSize="large" />
                </IconButton>
            )
        }
    ]

    return (
        <Container maxWidth="xl">
            <div className="credit">
                <div className="header">
                    <div>
                        <Typography variant="body1">
                            Account
                        </Typography>
                        <Typography variant="h6">
                            Admin@Project.com
                        </Typography>
                    </div>
                    <div className="balance">
                        <div className="value">
                            <Typography variant="body1">
                                Credit
                            </Typography>
                            <Typography variant="h6">
                                $200.000
                            </Typography>
                        </div>
                        <Button variant="contained">Top Up</Button>
                    </div>
                </div>
                <div className="custom-table">
                    <div className="header">
                        <div>
                            <Typography variant="h5">
                                Transaction
                            </Typography>
                        </div>
                        <div>
                            {/* <DateRangePicker open={openDate} toggle={() => setOpenDate(!openDate)} onChange={(range) => console.log(range)} /> */}
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                {columns.map((column: any, i: number) => (
                                    <th key={i}>
                                        {column.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {dataList.map((data: any, i: number) => (
                                <tr key={i}>
                                    {columns.map((column: any, j: number) => (
                                        column.customRender
                                            ? (
                                                <td key={j}>
                                                    <column.customRender data={data} />
                                                </td>
                                            )
                                            : (
                                                <td key={j}>
                                                    <Typography variant="body1">
                                                        {data[column.key]}
                                                    </Typography>
                                                </td>
                                            )
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Container>
    )
}