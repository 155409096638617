import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import { Container } from "@mui/system";
import logo from "../../../assets/images/logo.svg"
import AuthSignIn from "./AuthSignIn";
import bg_auth from "../../../assets/images/bg_auth.svg"
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import AuthSignUpA from "./AuthSignUpA";
import AuthSignUpB from "./AuthSignUpB";
import AuthSignUpC from "./AuthSignUpC";
import AuthSignUpD from "./AuthSignUpD";

export default function Auth() {

    const { contentIndex, loading } = useSelector((state: RootState) => state.auth)

    const contents = [
        <AuthSignIn />,
        <AuthSignUpA />,
        <AuthSignUpB />,
        <AuthSignUpC />,
        <AuthSignUpD />
    ]

    return (
        <Container maxWidth="xl">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="auth">
                <div className="header">
                    <img src={logo} alt="logo" />
                    <ul>
                        <li>
                            <Button color="inherit">ABOUT US</Button>
                        </li>
                        <li>
                            <Button color="inherit">SERVICE</Button>
                        </li>
                        <li>
                            <Button color="inherit">CONTACT US</Button>
                        </li>
                    </ul>
                </div>
                <Grid container className="body">
                    <img src={bg_auth} alt="logo" />
                    {contents[contentIndex]}
                </Grid>
            </div>
        </Container>
    )
}