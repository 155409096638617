import { AddLink, AttachFile, Delete, Link } from "@mui/icons-material";
import { Autocomplete, Button, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import CustomModal from "../../../shared/customModal/CustomModal";
import { userInfo } from "../../../shared/utils";
import { createProject, createProjectSupportingLink, getProjectByCompany } from "../project.api";
import ProjectCreateMap from "./ProjectCreateMap";

export default function ProjectCreate(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        token: "",
        projectNumber: "",
        companyId: "",
        quantity: 0,
        dueDateString: "",
        location: "",
        zipCode: "",
        address: "",
        city: "",
        county: ""
    })
    const [supportingLinkFormStatus, setSupportingLinkFormStatus] = useState(false)
    const [supportingLinkFormValue, setSupportingLinkFormValue] = useState("")
    const [supportingLinkList, setSupportingLinkList] = useState<any>([])
    const [modalCounty, setModalCounty] = useState(false)
    const [stateSelected, setStateSelected] = useState<any>(null)
    const [countyList, setCountyList] = useState([])

    useEffect(() => {
        let ufLinkData = localStorage.getItem("ufLinkData") || ""
        let ufLinkDataJson = JSON.parse(ufLinkData)
        setValues({
            ...values,
            token: ufLinkDataJson.token,
            companyId: ufLinkDataJson.user.companyId
        })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSelectState = (e: any, value: string) => {
        setStateSelected(value)
        setValues({
            ...values,
            county: ""
        })
        let stateSelected = userInfo().user.companyStates.filter((item: any) => item.state === value)[0]
        let counties = stateSelected.counties
        setCountyList(counties)
    }

    const handleSelectCounty = (e: any, value: string) => {
        setValues({
            ...values,
            county: value
        })
    }

    const handleDone = (e: any) => {
        e.preventDefault()
        setModalCounty(false)
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(createProject({
            ...values,
            token: userInfo().token,
            dueDateString: moment(new Date(values.dueDateString)).format("MM/DD/yyyy")
        }))
            .unwrap()
            .then((response: any) => {
                props.onClose()
                Swal.fire("Success")
                let insertId = response.data.data.id
                dispatch<any>(createProjectSupportingLink({
                    projectId: insertId,
                    links: supportingLinkList,
                    token: userInfo().token
                }))
                    .unwrap()
                    .then(() => {
                        dispatch<any>(getProjectByCompany({
                            skip: 0,
                            take: 100,
                            token: userInfo().token,
                            companyId: userInfo().user.companyId,
                            filter: ""
                        }))
                    })
            })
    }

    return (
        <div className="create">
            <CustomModal
                open={modalCounty}
                onClose={() => setModalCounty(false)}
                title="Choose County"
                size='sm'
                component={
                    <form onSubmit={handleDone}>
                        <Autocomplete
                            options={userInfo().user.companyStates.map((item: any) => item.state)}
                            value={stateSelected}
                            onChange={handleSelectState}
                            renderInput={(params) => <TextField {...params} margin="dense" fullWidth label="Choose state" />}
                        />
                        <Autocomplete
                            options={countyList}
                            value={values.county}
                            onChange={handleSelectCounty}
                            renderInput={(params) => <TextField {...params} margin="dense" fullWidth label="Choose county" required />}
                        />
                        <br />
                        <Button fullWidth variant="contained" type="submit">Done</Button>
                    </form>
                }
            />
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <TextField fullWidth margin="dense" onChange={handleChangeValues} value={values.projectNumber} name="projectNumber" label="Project Number" />
                        <TextField fullWidth margin="dense" onChange={handleChangeValues} value={values.number} type="number" name="quantity" label="Quantity" />
                        <TextField fullWidth margin="dense" onChange={handleChangeValues} value={values.date} type="date" name="dueDateString" label="Due Date" />
                        <div className="supporting-file">
                            <div className="header">
                                <Typography variant="body2" align="center">
                                    Supporting File
                                </Typography>
                            </div>
                            <div className="body">
                                <div className="upload">
                                    <IconButton>
                                        <AttachFile color="primary" />
                                    </IconButton>
                                </div>
                                <div className="link">
                                    <IconButton onClick={() => setSupportingLinkFormStatus(!supportingLinkFormStatus)}>
                                        <AddLink color="primary" />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        {supportingLinkFormStatus && (
                            <div style={{ textAlign: "right" }}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    size="small"
                                    variant="standard"
                                    label="link"
                                    onChange={(e) => setSupportingLinkFormValue(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault()
                                            setSupportingLinkList([...supportingLinkList, supportingLinkFormValue])
                                            setSupportingLinkFormStatus(false)
                                        }
                                    }} />
                                <Button>
                                    Done
                                </Button>
                            </div>
                        )}
                        <List dense>
                            {supportingLinkList.map((link: string) => (
                                <ListItem>
                                    <ListItemIcon>
                                        <Link />
                                    </ListItemIcon>
                                    <ListItemText primary={link} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" onClick={() => setSupportingLinkList(supportingLinkList.filter((item: string) => item !== link))}>
                                            <Delete />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item md={4}>
                        <Button fullWidth variant="outlined" className="choose" onClick={() => setModalCounty(true)}>Location choose on map</Button>
                        <TextField fullWidth margin="dense" onChange={handleChangeValues} value={values.address} name="address" label="Address" />
                        <TextField fullWidth margin="dense" onChange={handleChangeValues} value={values.city} name="city" label="City" />
                        <TextField fullWidth margin="dense" onChange={handleChangeValues} value={values.county} name="county" label="County" />
                    </Grid>
                    <Grid item md={4}>
                        <ProjectCreateMap county={values.county} values={values} setValues={setValues} />
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Button fullWidth variant="outlined" onClick={props.onClose}>Cancel</Button>
                    </Grid>
                    <Grid item md={6}>
                        <Button fullWidth variant="contained" type="submit">Ok</Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}