import { Button, Card, CardContent, Divider, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { login } from "../auth.api";
import { setContentIndex } from "../auth.reducer";

export default function AuthSignIn() {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({})

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(login(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    if (response.data.status) {
                        window.localStorage.setItem("ufLinkData", JSON.stringify(response.data.data))
                        window.location.replace('/')
                    }
                    else {
                        Swal.fire({
                            title: "Error",
                            text: response.data,
                            footer: "Promblem or question ? Email us as Contact@uflink.com"
                        })
                    }
                }
                else {
                    Swal.fire({
                        title: "Error",
                        text: response.data || response.statusText,
                        footer: "Promblem or question ? Email us as Contact@uflink.com"
                    })
                }
            })
            .catch((err: any) => {
                Swal.fire({
                    title: "Error",
                    text: err,
                    footer: "Promblem or question ? Email us as Contact@uflink.com"
                })
            })
    }

    return (
        <Grid item md={6}>
            <Card elevation={20}>
                <CardContent>
                    <Typography variant="h5">Login to Your Account</Typography>
                    <br />
                    <form onSubmit={handleSubmit}>
                        <TextField margin="dense" fullWidth onChange={handleChangeValues} type="userName" label="Email" name="userName" />
                        <TextField margin="dense" fullWidth onChange={handleChangeValues} type="password" label="Password" name="password" />
                        <Button type="submit" variant="contained">Login</Button>
                        <Typography variant="inherit" color="GrayText">Forgot password</Typography>
                    </form>
                    <br />
                    <Divider />
                    <Button variant="contained" onClick={() => dispatch(setContentIndex(1))}>Create New Company</Button>
                </CardContent>
            </Card>
        </Grid>
    )
}