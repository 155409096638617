import { createSlice } from '@reduxjs/toolkit'
import Swal from 'sweetalert2';
import { createProject, createProjectSupportingLink, getProjectBidList, getProjectByCompany, projectApproveBid } from './project.api';

export interface ProjectState {
    loading: boolean,
    dataList: any
}
const initialState: ProjectState = {
    loading: false,
    dataList: []
}

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createProject.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createProject.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createProject.rejected, (state, action: any) => {
            state.loading = false
            Swal.fire({
                icon: "error",
                html: action.payload?.data
            })
        })

        builder.addCase(createProjectSupportingLink.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createProjectSupportingLink.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createProjectSupportingLink.rejected, (state, action: any) => {
            state.loading = false
            Swal.fire({
                icon: "error",
                html: action.payload?.data
            })
        })

        builder.addCase(getProjectByCompany.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getProjectByCompany.fulfilled, (state, action) => {
            state.loading = false
            state.dataList = action.payload.data.data;
        })
        builder.addCase(getProjectByCompany.rejected, (state, action: any) => {
            state.loading = false
            Swal.fire({
                icon: "error",
                html: action.payload?.data
            })
        })

        builder.addCase(getProjectBidList.pending, (state, action) => {
            state.loading = false
        })
        builder.addCase(getProjectBidList.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getProjectBidList.rejected, (state, action: any) => {
            state.loading = false
            Swal.fire({
                icon: "error",
                html: action.payload?.data
            })
        })

        builder.addCase(projectApproveBid.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(projectApproveBid.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(projectApproveBid.rejected, (state, action: any) => {
            state.loading = false
            Swal.fire({
                icon: "error",
                html: action.payload?.data
            })
        })

    },
})

export default projectSlice.reducer