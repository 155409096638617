import { CircleNotifications, Logout, Person } from "@mui/icons-material";
import { Backdrop, CircularProgress, IconButton, List, ListItemButton, ListItemText, Popover, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userInfo } from "../../shared/utils";
import { RootState } from "../../store";

export default function Header() {

    const navigate = useNavigate()

    const loadingProject = useSelector((state: RootState) => state.project).loading
    const loadingRequest = useSelector((state: RootState) => state.request).loading
    const loadingVerification = useSelector((state: RootState) => state.verification).loading

    const [popAccount, setPopAccount] = useState<HTMLButtonElement | null>(null);
    const [userData, setUserData] = useState<any>({});

    useEffect(() => {
        setUserData(userInfo())
    }, [])

    const handleLogout = () => {
        localStorage.removeItem('ufLinkData')
        navigate("/auth")
    }

    return (
        <header>
            <Backdrop
                className="loading"
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={
                    loadingProject ||
                    loadingRequest ||
                    loadingVerification
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Popover
                open={Boolean(popAccount)}
                anchorEl={popAccount}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setPopAccount(null)}
            >
                <List>
                    <ListItemButton onClick={() => navigate('/account')}>
                        <ListItemText primary="My Account" />
                    </ListItemButton>
                    <ListItemButton onClick={handleLogout}>
                        <ListItemText primary="Logout" />
                        <Logout color="primary" />
                    </ListItemButton>
                </List>
            </Popover>
            <div className="profile">
                <div>
                    <Typography variant="caption">{userData?.user?.userName}</Typography>
                    <IconButton color="inherit" onClick={(e => setPopAccount(e.currentTarget))}>
                        <Person />
                    </IconButton>
                    <IconButton color="inherit">
                        <CircleNotifications />
                    </IconButton>
                </div>
                <div>
                    <Typography variant="caption">{userData?.user?.companyName}</Typography>
                </div>
            </div>
        </header>
    )
}