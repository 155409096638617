import { Search, Settings } from "@mui/icons-material"
import { Button, FormControl, InputAdornment, MenuItem, TextField, Typography } from "@mui/material"
import { Container } from "@mui/system"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import CustomModal from "../../../shared/customModal/CustomModal"
import { getProjectByCompany } from "../project.api"
import ProjectCreate from "./ProjectCreate"
import ProjectProposeList from "./ProjectProposeList"
import { userInfo } from "../../../shared/utils"
import { RootState } from "../../../store"

export default function Project() {

    const dispatch = useDispatch()

    const { dataList } = useSelector((state: RootState) => state.project)

    const [modalCreate, setModalCreate] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        if (!userInfo().token) {
            return
        }
        dispatch<any>(getProjectByCompany({
            skip: 0,
            take: 100,
            token: userInfo().token,
            companyId: userInfo().user.companyId,
            filter: ""
        }))
    }

    const columns = [
        {
            name: "Sub-Contract",
            key: "subcontract"
        },
        {
            name: "Project",
            key: "projectNumber"
        },
        {
            name: "PO Number",
            key: "number_po"
        },
        {
            name: "Requested QTY",
            key: "quantity"
        },
        {
            name: "City",
            key: "city"
        },
        {
            name: "County",
            key: "county"
        },
        {
            name: "Status",
            key: "status",
            customRender: (props: any) => (
                <span className={"status " + (props.data.status).replaceAll(' ', '')}>
                    {props.data.status}
                </span>
            )
        },
        {
            name: "Propose Bid",
            key: "propose",
            customRender: (props: any) => {
                if (props.data.approvedBidAmount === "$0.00") {
                    return (
                        <ProjectProposeList data={props.data} />
                    )
                }
                else {
                    return (
                        <>{props.data.approvedBidAmount}</>
                    )
                }
            }
        }
    ]

    return (
        <Container maxWidth="xl">
            <div className="custom-table project">
                <div className="header">
                    <div>
                        <FormControl fullWidth size="small" className="options">
                            <TextField label="Project Status" size="small" select className="options" fullWidth value={1}>
                                <MenuItem value={1}>Active</MenuItem>
                                <MenuItem value={2}>Completed</MenuItem>
                                <MenuItem value={3}>Archived</MenuItem>
                            </TextField>
                        </FormControl>
                        <CustomModal
                            open={modalCreate}
                            onClose={() => setModalCreate(false)}
                            title="Add Project"
                            component={
                                <ProjectCreate onClose={() => setModalCreate(false)} />
                            }
                        />
                        <Button variant="contained" className="create" onClick={() => setModalCreate(true)}>Create Project</Button>
                    </div>
                    <div>
                        <TextField
                            label="Search"
                            size="small"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><Search /></InputAdornment>
                            }}
                        />
                        <Button startIcon={<Settings />}>Filter</Button>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            {columns.map((column: any, i: number) => (
                                <th key={i}>
                                    {column.name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataList.map((data: any, i: number) => (
                            <tr key={i}>
                                {columns.map((column: any, j: number) => (
                                    column.customRender
                                        ? (
                                            <td key={j}>
                                                <column.customRender data={data} />
                                            </td>
                                        )
                                        : (
                                            <td key={j}>
                                                <Typography variant="body1">
                                                    {data[column.key]}
                                                </Typography>
                                            </td>
                                        )
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Container>
    )
}