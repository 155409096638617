import { Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setContentIndex, setFormSignup } from "../auth.reducer";

export default function AuthSignUpB() {

    const dispatch = useDispatch()

    const { formSignup } = useSelector((state: RootState) => state.auth)

    const handleChangeForm = (e: any) => {
        const { name, value } = e.target
        dispatch(setFormSignup({
            ...formSignup,
            [name]: value
        }))
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch(setContentIndex(3))
    }

    return (
        <Grid item md={6}>
            <Card elevation={20}>
                <CardContent>
                    <Typography variant="h5">Create New Company</Typography>
                    <Typography variant="inherit" color="GrayText">Profile</Typography>
                    <br />
                    <form onSubmit={handleSubmit}>
                        <TextField margin="dense" fullWidth onChange={handleChangeForm} type="name" label="Company Name" name="name" />
                        <TextField margin="dense" fullWidth onChange={handleChangeForm} type="email" label="Email" name="email" />
                        <TextField margin="dense" fullWidth onChange={handleChangeForm} type="phone" label="Phone" name="phone" />
                        <Typography variant="inherit" color="GrayText" align="right">02/04</Typography>
                        <Button type="submit" variant="contained">Next</Button>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    )
}