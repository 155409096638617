import { Autocomplete, Button, Card, CardContent, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setContentIndex, setFormSignup } from "../auth.reducer";
import counties_list from "../../../assets/data/counties_list.json"
import { Fragment, useEffect, useState } from "react";
import { Add, Delete } from "@mui/icons-material";
import { RootState } from "../../../store";

export default function AuthSignUpC() {

    const dispatch = useDispatch()

    const { formSignup } = useSelector((state: RootState) => state.auth)

    const [stateList, setStateList] = useState<any>([])
    const [stateSelected, setStateSelected] = useState("")
    const [countyList, setCountyList] = useState<any>([])
    const [countySelected, setCountySelected] = useState<any>([])

    useEffect(() => {
        let rawStateList: string[] = []
        counties_list.map(data => {
            if (!rawStateList.includes(data.State)) {
                rawStateList.push(data.State)
            }
        })
        setStateList(rawStateList)
    }, [])

    const handleSelectState = (e: any, value: any) => {
        setStateSelected(value)
        let rawCountyList: string[] = []
        counties_list.map(data => {
            if (data.State === value) {
                rawCountyList.push(data.County)
            }
        })
        setCountyList(rawCountyList)
        setCountySelected([])
    }

    const handleSelectCounty = (e: any, value: any) => {
        setCountySelected(value)
    }

    const handleAdd = () => {
        dispatch(setFormSignup({
            ...formSignup,
            states: [...formSignup.states, {
                state: stateSelected,
                counties: countySelected
            }]
        }))
        setStateSelected("")
        setCountySelected([])
    }

    const handleDeleteState = (value: string) => {
        dispatch(setFormSignup({
            ...formSignup,
            states: formSignup.states.filter((states: any) => states.state !== value)
        }))
    }

    const handleDeleteCounty = (state: string, value: string) => {
        dispatch(setFormSignup({
            ...formSignup,
            states: formSignup.states.map((states: any) => {
                if (states.state === state) {
                    return {
                        ...states,
                        counties: states.counties.filter((county: string) => county !== value)
                    }
                }
                else {
                    return states
                }
            })
        }))
    }

    return (
        <Grid item md={6}>
            <Card elevation={20}>
                <CardContent>
                    <Typography variant="h5">Create New Company</Typography>
                    <Typography variant="inherit" color="GrayText">Location</Typography>
                    <br />
                    <List disablePadding className="state-list">
                        {formSignup.states.map((states: any) => (
                            <Fragment key={states.state}>
                                <ListItem
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteState(states.state)}>
                                            <Delete />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText
                                        primary={states.state}
                                    />
                                </ListItem>
                                <List disablePadding>
                                    {states.counties.map((county: string) => (
                                        <ListItem
                                            key={county}
                                            secondaryAction={
                                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCounty(states.state, county)}>
                                                    <Delete />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemIcon></ListItemIcon>
                                            <ListItemText key={states.state}
                                                primary={county}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Fragment>
                        ))}
                    </List>
                    <br />
                    <form>
                        <Autocomplete
                            options={stateList}
                            value={stateSelected}
                            onChange={handleSelectState}
                            renderInput={(params) => <TextField {...params} margin="dense" fullWidth label="Choose state" />}
                        />
                        <Autocomplete
                            multiple
                            options={countyList}
                            value={countySelected}
                            onChange={handleSelectCounty}
                            renderInput={(params) => <TextField {...params} margin="dense" fullWidth label="Choose county" />}
                        />
                        <Button variant="outlined" endIcon={<Add />} onClick={handleAdd}>Add State</Button>
                        <Typography variant="inherit" color="GrayText" align="right">03/04</Typography>
                        <Button variant="outlined" onClick={() => dispatch(setContentIndex(4))}>Skip</Button>{" "}
                        <Button variant="contained" onClick={() => dispatch(setContentIndex(4))}>Complete</Button>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    )
}