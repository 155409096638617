import { Assignment, Money } from "@mui/icons-material"
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import logo from "../../assets/images/logo.svg"

export default function Sidetab() {

    const navigate = useNavigate()

    const [expanded, setExpanded] = useState("")

    const menuList = [
        {
            name: "Project",
            url: "/project",
            icon: <Assignment />
        },
        {
            name: "Request",
            url: "/request",
            icon: <Assignment />
        },
        {
            name: "New Registered Company",
            url: "/unverification",
            icon: <Assignment />
        },
        {
            name: "Credit",
            url: "/credit",
            icon: <Money />
        }
    ]

    const handleClickMenu = (menu: any) => {
        if (!menu.child) {
            navigate(menu.url)
        }
        else if (expanded === menu.url) {
            setExpanded("")
        }
        else {
            setExpanded(menu.url)
        }
    }

    return (
        <Drawer
            open={true}
            variant="permanent"
        >
            <div className="sidetab">
                <div className="brand">
                    <img src={logo} alt="logo" height={48} />
                </div>
                <List>
                    {menuList.map((menu, i) => (
                        <div key={i}>
                            <ListItemButton className={(
                                window.location.hash.includes(menu.url)
                                    ? " active"
                                    : ""
                            )} onClick={() => handleClickMenu(menu)}>
                                <ListItemIcon>
                                    {menu.icon}
                                </ListItemIcon>
                                <ListItemText primary={menu.name} />
                            </ListItemButton>
                        </div>
                    ))}
                </List>
            </div>
        </Drawer>
    )
}