import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const createProject = createAsyncThunk('/api/Project/UFLCreate', async (data: any, { rejectWithValue }) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/api/Project/UFLCreate',
            data: data
        })
        return response
    } catch (error: any) {
        return rejectWithValue(error.response)
    }
})

export const createProjectSupportingLink = createAsyncThunk('/api/Project/UFLSaveSupportingLinkAttachments', async (data: any, { rejectWithValue }) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/api/Project/UFLSaveSupportingLinkAttachments',
            data: data
        })
        return response
    } catch (error: any) {
        return rejectWithValue(error.response)
    }
})

export const getProjectByCompany = createAsyncThunk('/api/Project/UFLGetListByCompanyId', async (data: any, { rejectWithValue }) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/api/Project/UFLGetListByCompanyId',
            data: data
        })
        return response
    } catch (error: any) {
        return rejectWithValue(error.response)
    }
})

export const getProjectBidList = createAsyncThunk('/api/Project/UFLGetProjectBidList', async (data: any, { rejectWithValue }) => {
    try {
        const response = await axios({
            method: 'get',
            url: baseUrl + '/api/Project/UFLGetProjectBidList',
            params: data
        })
        return response
    } catch (error: any) {
        return rejectWithValue(error.response)
    }
})

export const projectApproveBid = createAsyncThunk('/api/Project/UFLApproveBid', async (data: any, { rejectWithValue }) => {
    try {
        const response = await axios({
            method: 'put',
            url: baseUrl + '/api/Project/UFLApproveBid',
            params: data
        })
        return response
    } catch (error: any) {
        return rejectWithValue(error.response)
    }
})