import { createSlice } from '@reduxjs/toolkit'
import Swal from 'sweetalert2';
import { createProjectBid, getProjectByCounties } from './request.api';

export interface RequestState {
    loading: boolean,
    dataList: any
}
const initialState: RequestState = {
    loading: false,
    dataList: []
}

export const requestSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getProjectByCounties.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getProjectByCounties.fulfilled, (state, action) => {
            state.loading = false
            state.dataList = action.payload.data.data;
        })
        builder.addCase(getProjectByCounties.rejected, (state, action: any) => {
            state.loading = false
            Swal.fire({
                icon: "error",
                html: action.payload?.data
            })
        })

        builder.addCase(createProjectBid.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createProjectBid.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createProjectBid.rejected, (state, action: any) => {
            state.loading = false
            Swal.fire({
                icon: "error",
                html: action.payload?.data
            })
        })

    },
})

export default requestSlice.reducer