import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { activateCompany, createCompany, login } from './auth.api';

export interface AuthState {
    loading: boolean,
    contentIndex: number,
    formSignup: any
}
const initialState: AuthState = {
    loading: false,
    contentIndex: 0,
    formSignup: {
        states: []
    }
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setContentIndex: (state, action: PayloadAction<any>) => {
            state.contentIndex = action.payload;
        },
        setFormSignup: (state, action: PayloadAction<any>) => {
            state.formSignup = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(createCompany.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createCompany.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createCompany.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(activateCompany.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(activateCompany.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(activateCompany.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(login.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export const {
    setContentIndex,
    setFormSignup
} = authSlice.actions;

export default authSlice.reducer