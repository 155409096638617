import { AddLink, AttachFile } from "@mui/icons-material";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { userInfo } from "../../../shared/utils";
import { createProjectBid, getProjectByCounties } from "../request.api";
import RequestGetMap from "./RequestGetMap";

export default function RequestGet(props: any) {

    const dispatch = useDispatch()

    const [bidValue, setBidValue] = useState(0)

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(createProjectBid({
            token: userInfo().token,
            projectId: props.data.id,
            bidderId: userInfo().user.id,
            bidAmount: bidValue
        }))
            .unwrap()
            .then((response: any) => {
                props.onClose()
                Swal.fire("Success")
                let countyList: any = []
                userInfo().user.companyStates.map((state: any) => (
                    state.counties.map((county: any) => (
                        countyList.push(county)
                    ))
                ))
                dispatch<any>(getProjectByCounties({
                    skip: 0,
                    take: 100,
                    token: userInfo().token,
                    counties: countyList,
                    filter: ""
                }))
            })
    }

    return (
        <div className="create">
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item md={8}>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <TextField fullWidth margin="dense" value={props.data.projectNumber} name="projectNumber" label="Project Number" />
                                <TextField fullWidth margin="dense" value={props.data.quantity} type="number" name="quantity" label="Quantity" />
                                <TextField fullWidth margin="dense" value={moment(props.data.date).format("YYYY-MM-DD")} type="date" name="dueDateString" label="Due Date" />
                                <div className="supporting-file">
                                    <div className="header">
                                        <Typography variant="body2" align="center">
                                            Supporting File
                                        </Typography>
                                    </div>
                                    <div className="body">
                                        <div className="upload">
                                            <IconButton>
                                                <AttachFile color="primary" />
                                            </IconButton>
                                        </div>
                                        <div className="link">
                                            <IconButton>
                                                <AddLink color="primary" />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <TextField fullWidth margin="dense" value={props.data.address} name="address" label="Address" />
                                <TextField fullWidth margin="dense" value={props.data.city} name="city" label="City" />
                                <TextField fullWidth margin="dense" value={props.data.county} name="county" label="County" />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <TextField fullWidth type="number" placeholder="Enter you bid" size="small" value={bidValue} onChange={(e) => setBidValue(parseInt(e.target.value))} />
                            </Grid>
                            <Grid item md={4}>
                                <Button fullWidth variant="contained" type="submit">Propose Bid</Button>
                            </Grid>
                            <Grid item md={4}>
                                <Button fullWidth variant="contained" color="error">Delete</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4}>
                        <RequestGetMap location={props.data.location} />
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}