import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setContentIndex, setFormSignup } from "../auth.reducer";

export default function AuthSignUpA() {

    const dispatch = useDispatch()

    const { formSignup } = useSelector((state: RootState) => state.auth)

    const handleChooseType = (value: number) => {
        dispatch(setFormSignup({
            ...formSignup,
            companyType: value
        }))
    }

    return (
        <Grid item md={6}>
            <Card elevation={20}>
                <CardContent>
                    <Typography variant="h5">Create New Company</Typography>
                    <Typography variant="inherit" color="GrayText">Choose Company Type</Typography>
                    <br />
                    <Grid container spacing={5}>
                        <Grid item md={6}>
                            <Card className={formSignup.companyType === 0 ? 'option active' : 'option'} onClick={() => handleChooseType(0)}>
                                <CardContent>
                                    <Typography variant="h5">Project</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={6}>
                            <Card className={formSignup.companyType === 1 ? 'option active' : 'option'} onClick={() => handleChooseType(1)}>
                                <CardContent>
                                    <Typography variant="h5">Fielding</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Typography variant="inherit" color="GrayText" align="right">01/04</Typography>
                    <Button variant="contained" onClick={() => dispatch(setContentIndex(2))}>Next</Button>
                </CardContent>
            </Card>
        </Grid>
    )
}