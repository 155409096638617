import { createTheme, ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import "./assets/styles/styles.scss"
import Layout from './containers/components/Layout';

import Auth from './pages/auth/component/Auth';
import AuthVerification from './pages/auth/component/AuthVerification';

import Account from './pages/account/component/Account';

import Project from './pages/project/component/Project';
import Request from './pages/request/component/Request';
import Verification from './pages/verification/component/Verification';
import Credit from './pages/credit/component/Credit';

function App() {

  const navigate = useNavigate()

  useEffect(() => {
    let ufLinkData = localStorage.getItem("ufLinkData")
    let ufLinkDataJson = JSON.parse(ufLinkData || "{}")
    if (["#/verification"].includes(window.location.hash.split('?')[0])) {

    }
    else {
      if (ufLinkDataJson.token) {
        let epochExpired = new Date(ufLinkDataJson.expiredTime).getTime()
        let epochNow = new Date().getTime()
        if (epochExpired < epochNow) {
          localStorage.removeItem('ufLinkData')
          navigate("/auth")
        }
      }
      else {
        navigate('/auth')
      }
    }
  }, [])

  const theme = createTheme({
    palette: {
      primary: {
        main: "#08A79D",
        contrastText: "#FFFFFF"
      }
    },
    shape: {
      borderRadius: 20
    }
  })

  const routeList = [
    {
      path: "/project",
      component: <Project />
    },
    {
      path: "/request",
      component: <Request />
    },
    {
      path: "/account",
      component: <Account />
    },
    {
      path: "/unverification",
      component: <Verification />
    },
    {
      path: "/credit",
      component: <Credit />
    }
  ]

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {routeList.map((item, i) => (
          <Route key={i} path={item.path} element={<Layout component={item.component} />} />
        ))}
        <Route path="/auth" element={<Auth />} />
        <Route path="/verification" element={<AuthVerification />} />
        <Route path="/" element={<Navigate to={routeList[0].path} />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;