import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import authReducer from './pages/auth/auth.reducer'
import projectReducer from './pages/project/project.reducer'
import requestReducer from './pages/request/request.reducer'
import verificationReducer from './pages/verification/verification.reducer'

const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
    reducer: {
        auth: authReducer,

        project: projectReducer,
        request: requestReducer,
        verification: verificationReducer
    },
})

export type RootState = ReturnType<typeof store.getState>

export default store