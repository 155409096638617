import { Search, Settings } from "@mui/icons-material"
import { Button, InputAdornment, TextField, Typography } from "@mui/material"
import { Container } from "@mui/system"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { getVerification, sendVerification } from "../verification.api"
import { userInfo } from "../../../shared/utils"
import Swal from "sweetalert2"

export default function Verification() {

    const dispatch = useDispatch()

    const [dataList, setDataList] = useState<any>([])

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        dispatch<any>(getVerification({
            skip: 0,
            take: 100,
            token: userInfo().token
        }))
            .unwrap()
            .then((response: any) => {
                setDataList(response.data.data)
            })
    }

    const handleSendVerification = (data: any) => {
        dispatch<any>(sendVerification({
            email: data.email
        }))
            .unwrap()
            .then((response: any) => {
                Swal.fire("Success", response.data)
            })
    }

    const columns = [
        {
            name: "Company Name",
            key: "companyName"
        },
        {
            name: "Email",
            key: "email"
        },
        {
            name: "Phone Number",
            key: "phoneNumber"
        },
        {
            name: "Action",
            key: "id",
            customRender: (props: any) => {
                return (
                    <Button variant="contained" onClick={() => handleSendVerification(props.data)}>
                        Send Verification
                    </Button>
                )
            }
        }
    ]

    return (
        <Container maxWidth="xl">
            <div className="custom-table">
                <div className="header">
                    <div>
                    </div>
                    <div>
                        <TextField
                            label="Search"
                            size="small"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><Search /></InputAdornment>
                            }}
                        />
                        <Button startIcon={<Settings />}>Filter</Button>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            {columns.map((column: any, i: number) => (
                                <th key={i}>
                                    {column.name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataList.map((data: any, i: number) => (
                            <tr key={i}>
                                {columns.map((column: any, j: number) => (
                                    column.customRender
                                        ? (
                                            <td key={j}>
                                                <column.customRender data={data} />
                                            </td>
                                        )
                                        : (
                                            <td key={j}>
                                                <Typography variant="body1">
                                                    {data[column.key]}
                                                </Typography>
                                            </td>
                                        )
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Container>
    )
}