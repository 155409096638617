import Sidetab from "./Sidetab";
import Header from "./Header";

export default function Layout(props: any) {
    return (
        <>
            <Sidetab />
            <div className="main">
                <Header />
                <div className="content">
                    <br />
                    {props.component}
                    <br />
                </div>
            </div>
        </>
    )
}