import { createSlice } from '@reduxjs/toolkit'
import { getVerification, sendVerification } from './verification.api'

export interface VerificationState {
    loading: boolean
}
const initialState: VerificationState = {
    loading: false
}

export const verificationSlice = createSlice({
    name: 'verification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getVerification.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getVerification.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getVerification.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(sendVerification.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(sendVerification.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(sendVerification.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default verificationSlice.reducer