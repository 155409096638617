import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '100%'
};

export default function RequestGetMap(props: any) {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDQ-aesOz_ut1yBv_d6FG-0N60uqDwgLZs'
    })

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={10}
            center={{
                lat: parseFloat(props.location.split(',')[0]),
                lng: parseFloat(props.location.split(',')[1])
            }}
        >
            <Marker
                position={{
                    lat: parseFloat(props.location.split(',')[0]),
                    lng: parseFloat(props.location.split(',')[1])
                }}
            />
        </GoogleMap>
    ) : <></>
}