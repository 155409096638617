import { ArrowBack } from "@mui/icons-material";
import { Button, Divider, IconButton, TextField } from "@mui/material";
import { userInfo } from "../../../shared/utils";

export default function AccountCompany(props: any) {
    return (
        <div className="detail">
            <IconButton onClick={props.onBack}>
                <ArrowBack color="primary" />
            </IconButton>
            <Divider />
            <div className="values-recent">
                <span className="label">
                    Company Name
                </span>
                <span className="value">
                    {userInfo().user.companyName}
                </span>
            </div>
            <div className="values-new">
                <TextField fullWidth size="small" label="New Company Name" />
                <Button variant="contained">Save</Button>
            </div>
        </div>
    )
}